import React from 'react'

// Components
import BannerDefault from 'components/flex/Banner/BannerDefault'
import BannerHome from 'components/flex/Banner/BannerHome'
import BannerPost from 'components/flex/Banner/BannerPost'
import BannerVacancy from 'components/flex/Banner/BannerVacancy'
import BannerSupport from 'components/flex/Banner/BannerSupport'
import BannerContact from 'components/flex/Banner/BannerContact'
import BannerSearch from 'components/flex/Banner/BannerSearch'
import BannerEvent from './BannerEvent'

interface BannerShellProps {
  fields: any
  location?: any
  pageContext?: any
}

interface BannersProps {
  [key: string]: any
}

const BannerShell: React.FC<BannerShellProps> = ({
  fields,
  location = {},
  pageContext,
}) => {
  const banners: BannersProps = {
    default: BannerDefault,
    home: BannerHome,
    post: BannerPost,
    vacancy: BannerVacancy,
    support: BannerSupport,
    contact: BannerContact,
    search: BannerSearch,
    event: BannerEvent,
  }

  if (!fields.styletype || !banners[fields.styletype]) {
    return null
  }

  const Component = banners[fields.styletype]

  return (
    <Component
      key={`${location ? location.pathname : 'flex'}`}
      fields={fields}
      location={location}
      pageContext={pageContext}
    />
  )
}

export default BannerShell
