import React from 'react'

// Elements
import ParseContent from 'components/shared/ParseContent'

// Third Party
import styled, { css } from 'styled-components'

interface TextProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Text
  location?: any
}

const Content = styled(ParseContent)`
  & h2 {
    font-size: 22px;
    line-height: 26px;
    font-family: ${({ theme }) => theme.font.family.secondary};
    font-weight: ${({ theme }) => theme.font.weight.bold};
  }

  & h3 {
    font-size: 22px;
    line-height: 26px;
    font-family: ${({ theme }) => theme.font.family.secondary};
    font-weight: ${({ theme }) => theme.font.weight.bold};
  }

  & p {
    color: ${({ theme }) => theme.color.grey};
  }
`

const StyledText = styled.section<{ color: any }>`
  ${({ color, theme }) =>
    color === 'greyLight' &&
    css`
      background-color: ${theme.color.greyLight};
    `}
`

const Text: React.FC<TextProps> = ({ fields }) => (
  <StyledText color={fields.color}>
    <div className="container py-lg-5">
      <div className={`row py-5 justify-content-${fields.alignment}`}>
        <div className={`col-lg-${fields.width}`}>
          <Content content={fields.description} />
        </div>
      </div>
    </div>
  </StyledText>
)

export default Text
