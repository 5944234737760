import React from 'react'
import { useStaticQuery, graphql, navigate } from 'gatsby'

// Components
import Title from 'components/elements/Title'
import ParseContent from 'components/shared/ParseContent'

// Images
import Message from 'img/message.inline.svg'

// Third Party
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Autoplay } from 'swiper/core'
import styled from 'styled-components'
import Plaatjie from '@ubo/plaatjie'
import ButtonPrimary from 'components/elements/Buttons/ButtonPrimary'
import { cloneDeep } from 'lodash'

SwiperCore.use([Autoplay])

interface TeamSelectProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Components
}

const ImageWrapper = styled.div`
  width: 100%;
  padding-top: 100%;
  position: relative;
  filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.25));
  border-radius: 25px;

  @media screen and (max-width: 991px) {
    width: 100%;
    margin-left: auto;
  }
`

const StyledPlaatjie = styled(Plaatjie)`
  position: absolute !important;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  border-radius: 25px;
`

const Content = styled(ParseContent)`
  /* font-family: ${({ theme }) => theme.font.family.secondary}; */

  & p {
    font-size: 18px;
    line-height: 24px;
    /* font-weight: ${({ theme }) => theme.font.weight.extraBold}; */

    & b,
    strong {
      font-size: 20px;
    }
  }

  @media screen and (max-width: 575px) {
    & p {
      font-size: 20px;
      line-height: 20px;
    }
  }

  @media screen and (max-width: 350px) {
    & p {
      font-size: 16px;
      line-height: 16px;
    }
  }

  @media screen and (max-width: 991px) {
    font-size: 14px;
    line-height: 18px;
    color: ${({ theme }) => theme.color.grey};
  }
`

const SwiperWrapper = styled.div`
  height: 100%;
  width: 120%;
  position: relative;

  & .swiper-slide {
    height: auto;
  }
`

const SwiperShadow = styled.div`
  position: absolute;
  left: 50%;
  top: -80px;
  height: 90%;
  width: 100%;
  background-color: ${({ theme }) => theme.color.primary};
  border-radius: 25px;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.3);

  @media screen and (max-width: 991px) {
    left: 50%;
    top: -40px;
    height: 60%;
  }
`

const Bubble = styled.div`
  position: absolute;
  top: 5px;
  right: 5px;
  background-color: ${({ theme }) => theme.color.primary};
  padding: 10px;
  border-radius: 999px;
  transition: all 0.3s ease;
  cursor: pointer;

  &:hover {
    transform: scale(1.05);
  }
`

const TeamSelect: React.FC<TeamSelectProps> = ({ fields }) => {
  return (
    <section id="team">
      <div className="container py-5">
        <Title>
          <ParseContent content={fields?.description} />
        </Title>
        <div className="row">
          {fields?.teammembers?.map((member) => (
            <div className="col-6 col-lg-3">
              <ImageWrapper>
                <StyledPlaatjie
                  key={member?.id}
                  image={member?.teamrecap?.image}
                  alt={member?.title || ''}
                />
                {member?.teamrecap?.story && (
                  <Bubble
                    onClick={() =>
                      navigate(member?.teamrecap?.story?.uri || '/')
                    }
                  >
                    <Message />
                  </Bubble>
                )}
              </ImageWrapper>
              <div className="py-lg-2 py-1 px-3">
                <Content content={member?.teamrecap?.excerpt} />
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}

export default TeamSelect
